import queryString from 'query-string'
import { PAGE_ITEMS } from './Constant'
import { removeEmptyProp } from './ObjectUltils'
export function parsePramsToQueryString(params: object): string {
  const final = removeEmptyProp(params)
  return queryString.stringify(final, { arrayFormat: 'bracket', encode: false })
}
export function parseParamsToQueryStringRemoveBracket(params: object): string {
  const final = removeEmptyProp(params)
  return queryString.stringify(final, { encode: false })
}
export function toPagingAPI(URL: string, query?: any, page?: number, pageItems?: number): string {
  const finalQuery = query ? { ...query, items: pageItems || PAGE_ITEMS } : { items: pageItems || PAGE_ITEMS }
  return `${URL}${finalQuery ? `?${parsePramsToQueryString(page ? { ...finalQuery, page } : finalQuery)}` : ''}`
}
